const videos = {
  data: [
    { urlId: '933682912', title: 'Skicka kapillära prover till labb', description: "Instruktionsvideo från Prodia på hur du skickar kapillära prover till vårt ackrediterade laboratorium.", pdfFile: "/files/Instruktion_Kapillär_Provtagning.pdf" },
    { urlId: '310503065', title: 'Instruktion - Skicka urinanalys till labb', description: "Instruktionsvideo från Prodiagnostics på hur du skickar en urinanalys till vårt ackrediterade laboratorium." },
/*
    { urlId: '309452617', title: 'Instruktion - Skicka salivanalys till labb', description: "Instruktionsvideo från Prodiagnostics på hur du skickar en salivanalys till vårt ackrediterade laboratorium." },
*/
    { urlId: '353731966', title: 'Instruktion - Analys av preparat UTAN mugg', description: "Instruktionsvideo från Prodiagnostics på hur du analyserar preparat UTAN mugg." },
    { urlId: '353732711', title: 'Analys av preparat MED mugg', description: "Instruktionsvideo från Prodiagnostics på hur du analyserar preparat MED mugg." },
    { urlId: '353733748', title: 'Urinprov från klient', description: "Instruktionsvideo från Prodiagnostics på hur du bör ta ett bra urinprov." },
    { urlId: '353733709', title: 'Prodiagnostics integrerade mugg', description: "Instruktionsvideo från Prodiagnostics på hur du använder integrerad mugg." },
    { urlId: '353733787', title: 'Undvik manipulation', description: "Instruktionsvideo från Prodiagnostics på hur du gör för att undvika manipulation i ett urinprov." },
    { urlId: '353733807', title: 'Fel vid för mycket substans', description: "Instruktionsvideo från Prodiagnostics på hur du gör när du får för mycket av ett preparat." },
    { urlId: '353733818', title: 'Garanti', description: "Instruktionsvideo från Prodiagnostics på hur du använder dig av vår garanti." }
  ]
}

export default videos;